import Zendesk from '#images/common/zendesk.svg';
import styles from './ZendeskInit.module.scss';
import zendesk from '#utils/zendesk';
import { useEffect, useRef, useState } from 'react';

const ZendeskInitButton = () => {
  const ref = useRef<HTMLImageElement>(null);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();

  const handleOpenZendesk = () => {
    if (!document.getElementById('ze-snippet')) {
      const imgElement: HTMLImageElement | null = ref.current;
      zendesk.init();
      if (imgElement) {
        const timer = setTimeout(() => (imgElement.style.display = 'none'), 500);
        setTimeoutId(timer);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <img
      ref={ref}
      className={styles.btn}
      src={Zendesk.src}
      width="136"
      height="46"
      alt="zendesk"
      loading="eager"
      role="presentation"
      onClick={handleOpenZendesk}
    />
  );
};

export default ZendeskInitButton;
